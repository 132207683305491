import "./index.css"
import img1 from "./../../assets/images/Carousel/100x logo 1.png"
import img2 from "./../../assets/images/Logos/Mask group.png"
import img3 from "./../../assets/images/Carousel/Lets Venture logo 1.png"
import img4 from "./../../assets/images/Carousel/image 488.png"
import img5 from "./../../assets/images/Carousel/image 489.png"
import img6 from "./../../assets/images/Logos/apollo 2x.png"
import img7 from "./../../assets/images/Logos/beato 2x.png"
import img8 from "./../../assets/images/Logos/aqar 2x.png"
import img9 from "./../../assets/images/Logos/orange 2x.png"
import img10 from "./../../assets/images/Logos/freakins logo.png"
import img11 from "./../../assets/images/Logos/minifeel white logo.png"

import { Grid, Typography } from "@material-ui/core"

function Carousel({ isMobile = false }) {
  const images = [
    img1,
    img2,
    img10,
    img11,
    img3,
    img4,
    img5,
    img6,
    img10,
    img11,
    img7,
    img8,
    img9,
    img1,
    img2,
    img10,
    img11,
    img3,
    img4,
    img5
  ]
  return (
    <Grid item xs={12} style={{ margin: isMobile ? "0 -8.5%" : "1rem -8.5%" }}>
      {/* <Typography>
        Our Partners
      </Typography> */}
      <div class="slider" style={{
        backgroundColor: "#494949",
        width: isMobile ? "103vw" : "100vw",
        height: isMobile ? "180px" : "200px"
      }}>
        <div class="slide-track">
          {images.map((ele, index) => (
            <div class="slide">
              <img
                src={ele}
                height={isMobile ? "180" : "220"}
                width={isMobile ? "180" : "200"}
                alt=""
                style={{ objectFit: "contain" }}
              />
            </div>
          ))}
        </div>
      </div>
    </Grid>
  )
}

export default Carousel
