import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core"
import { CheckCircle, InfoOutlined, InfoRounded } from "@material-ui/icons"
import "./index.css"
import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { colors } from "../../colors"
import StartButton from "../../Components/Common/StartButton"
import ContactUs from "../../Components/ContactUs"
import PricingSwitch from "./../../Components/PricingSwitch/index"
import mixpanel from "mixpanel-browser"
import PricingTable from "../../Components/PricingTable"
import GenericTextfield from "../../Components/Common/GenericTextfield"
import enterpriseImage from "./../../assets/images/Pricing/Group 36918.png"
import axios from "axios"
import { toast } from "react-toastify"
import EnterpriseForm from "../../Components/EnterpriseForm"
import FAQ from "../../Components/FAQ"
function Pricing({ isMobile }) {
  const [plan, setPlan] = useState(true)
  const [open, setOpen] = useState(false)

  const pricingQuestions = [
    {
      question: "Is there a trial for your services?",
      answer:
        "We do provide a free trial of 2 weeks for our EMR solution only. Our premium services are prepaid.",
    },
    {
      question: "I am already using an EMR product. Hence, I only need website and advertisement services from us.",
      answer:
        "Paperplane’s marketing comprehensive package works best in sync with our EMR solution for seamless patient experience. New patients will be able to reach out and book appointments with you on Whatsapp. We also help in migration of your previous patient data to our EMR.",
    },
    {
      question: "What are the hardware requirements to use Paperplane?",
      answer:
        "Paperplane works on laptop/desktop and a mobile application for doctors and their staff. Patient’s will be able to stay connected with you on Whatsapp.",
    },
    {
      question: "How will patients be able to reach out to me or book appointments?",
      answer:
        "Paperplane’s 24/7 WhatsApp chatbot assistant helps patients to book appointments, chat and create ABHA ids. Additionally, they can also pay consultation fees, share important reports and documents.",
    },
    {
      question: "I already have a website. Can I get Whatsapp Button integration?",
      answer:
        "Yes, that is possible with the Paperplane Pro and Paperplane Super Doc package.",
    },
    {
      question: "Is there a limit for Whatsapp broadcasts?",
      answer:
        "There is a limit of 1000 messages/day for the broadcast from your verified account. The limit increases with respect to doctor-patient engagement (Appointment booking, Rx sharing, Chat with patients, etc)",
    },
    {
      question: "What is the process of onboarding doctors?",
      answer:
        `Post payment for the package, the business manager will connect you with a relationship manager. The RM will help you with the signup, training and all integrations. You will be entitled to receive 1-2 training sessions for any doubts by the business manager.

        For website and advertising services, the RM will always be a point of contact for the entire year.`,
    },
    {
      question: "I don’t have a GMB profile. Will you create it for me?",
      answer:
        `Yes. Our support team will help in creation of the profile and update all necessary information for patients to reach out to you.`,
    },
    {
      question: "How many days will it take for all the integrations?",
      answer:
        `It takes around 7-10 days for the integration. The business manager will take the required details via google form to initiate the process.`,
    },
    {
      question: "Whom should I connect incase of any query or issue in the product/service?",
      answer:
        `You will get a dedicated relationship manager to assist you. Also, our support team is available on Whatsapp chat as well.`,
    },
    {
      question: "I already have a whatsapp account. Can I use the same for verification?",
      answer:
        `The existing Whatsapp account has to be deleted. A new account will be created and verified. All the templates are then approved for that number. It takes around 7-10 days for the process to get completed. Till then all the messages will be delivered to patients from Paperplane’s common bot number.`,
    },
    {
      question: "How is Paperplane different from other EMR players?",
      answer:
        `Paperplane builds digital clinics on WhatsApp for doctors and hospitals to start and scale their medical practice on the world's most popular messaging app! With our marketing solution, doctors can build their visibility online and reach a wider audience to grow their brand and practice. More than 3000 doctors trust Paperplane across India. Brands like Apollo 247 and De9to use Paperplane for their doctors.`,
    },
    {
      question: "Is the pricing customised to my requirements?",
      answer:
        `Our existing packages are customizable according to your needs. Our business manager will understand your requirements and suggest the pricing accordingly.`,
    },
    {
      question: "Is my data safe?",
      answer:
        `Our product is HIPPA Compliant as well as ISO Certified so all the data of the client is stored in encrypted format on cloud which is completely secure. To read our privacy policy please visit https://www.paperplane.health/privacyPolicy`,
    },
    {
      question: "Will I get assurance for new patients by using your product and services?",
      answer:
        `Using our services, doctors will be able to reach a wider audience in your area. Paperplane offers the doctor the ability to establish their own brand and attract new patients via a variety of channels, including their website, organic search results on Google Search (Via Google My Business), advertisements on a variety of social media platforms, along with WhatsApp Marketing. `,
    },
    {
      question: "How can I increase my reviews on google?",
      answer:
        `With GMB integration, you can send requests for writing reviews to your patients from the EMR.`,
    },
    {
      question: "I don’t use the appointment system. How can I ensure that patients don’t book any appointments?",
      answer:
        `Using the appointment system helps in larger engagement with your patients on WhatsApp. If this feature is not required, you can disable the schedule to avoid any online appointments.`,
    },
    {
      question: "Which languages does Paperplane support?",
      answer:
        `At present, our product supports English, Hindi and Marathi.`,
    },
    {
      question: "There are less features and data with respect to my specialty. I won’t be able to use the EMR.",
      answer:
        `We always take feedback from doctors to develop any features for your specialty. Hence, if any such requirement arises while using the product, our team will discuss it with you for further implementation. The development of any feature depends on the urgency, feasibility and available bandwidth for development.`,
    },
    {
      question: "Is it possible to send important communication to my patients?",
      answer:
        `You can use WhatsApp broadcast feature to send any communication to your patients such as clinic closed, change in address or timings, promote new services, etc. This feature helps you to engage with your patients on a frequent basis and promote your practice.`,
    },
    {
      question: "Paperplane’s pricing is higher as compared to its competitors. Why should I pay more for the service?",
      answer:
        `By choosing our service, you're investing in a superior product with unmatched value. Our EMR solution and marketing package offer comprehensive features, seamless integration, and exceptional support. With our service, you'll experience increased efficiency, improved patient engagement, and a strong online presence. While the upfront cost may be higher, the long-term benefits and competitive advantage you'll gain make it a worthwhile investment for your healthcare practice.`,
    },
  ]

  return (
    <Grid container justifyContent="center">
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Creator Checkout - Use our Creators as Resellers and convert views to purchases !
        </title>
      </Helmet>
      <Grid
        item
        xs={12}
        style={{
          marginBottom: "2rem",
          textAlign: "center",
        }}
      >
        <Typography align="center" variant="h1">
          Plans that best suit your medical practice requirements! ✌️
        </Typography>
        {/* <Typography
          align="center"
          variant="h6"
          color="textSecondary"
          style={{ marginTop: "0.5rem" }}
        >
          <br />
          *The following prices are valid for{" "}
          <strong>individual practitioners</strong> only.
          <br /> Click{" "}
          <span
            onClick={() => setOpen(true)}
            style={{ color: "blue", cursor: "pointer" }}
          >
            here
          </span>{" "}
          to know more about our enterprise plans.
        </Typography> */}
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <PricingTable isMobile={isMobile} plan={plan} setPlan={setPlan} />
      </Grid>
      {/* <Grid item xs={12} style={{ textAlign: "center", margin: "1rem 0" }}>
        <StartButton
          label="Start 15-days trial now"
          to="https://website.paperplane.clinic"
        />
        <Typography
          style={{
            marginTop: "0.5rem",
            marginLeft: isMobile ? "0.7rem" : "1.4rem",
          }}
        >
          Takes less than 60s ⚡
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", margin: "1rem 0" }}>
        <Typography variant="h3" style={{ marginBottom: "1rem" }}>
          For enterprises 🏨:
        </Typography>
        <StartButton
          label="Contact Now"
          onClick={() => setOpen(true)}
        ></StartButton>
      </Grid> */}
      <EnterpriseForm open={open} setOpen={setOpen} isMobile={isMobile} />
      <ContactUs isMobile={isMobile} />

      <FAQ isMobile={isMobile} questions={pricingQuestions}/>
    </Grid>
  )
}

export default Pricing
