import { Grid, Typography, withWidth } from "@material-ui/core"
import React, { useState } from "react"
import AboutUs from "../../Components/AboutUs"
import BackedBy from "../../Components/BackedBy"
import Body from "../../Components/Body"
import Switch from "../../Components/Common/Switch"
import ContactUs from "../../Components/ContactUs"
import FAQ from "../../Components/FAQ"
import Hero from "../../Components/Hero"
import Testimonials from "../../Components/Testimonials"
import Video from "../../Components/Video"
import Carousel from "../../Components/Carousel"
//
import eImg1 from "../../assets/gifs/b1_2_1.gif"
import eImg2 from "../../assets/gifs/b2_2.gif"
import eImg3 from "../../assets/gifs/b3.gif"
import eImg4 from "../../assets/gifs/b4_2_2.gif"
//

import img1 from "../../assets/gifs/C_1.gif"
import img2 from "../../assets/gifs/c_2.gif"
import img3 from "../../assets/gifs/c3_1.gif"
import img4 from "../../assets/gifs/c4.gif"

// import individual from "./../../assets/images/ClinicBody/_2556293678288.png"
// import smallClinic from "./../../assets/images/ClinicBody/polyclinic-2 1.png"
import { colors } from "../../colors"
// import Eimg1 from "./../../assets/images/EnterpriseBody/Group 36845.png"
// import Eimg2 from "./../../assets/images/EnterpriseBody/Frame 3836.png"
// import Eimg3 from "./../../assets/images/EnterpriseBody/Group 36857 (1).png"
// import Eimg4 from "./../../assets/images/EnterpriseBody/Group 36868.png"
// import Eimg5 from "./../../assets/images/EnterpriseBody/Group 36859.png"
// import hospital from "./../../assets/images/EnterpriseBody/hospital-1 1.png"
// import multiple from "./../../assets/images/EnterpriseBody/_2556408270704.png"
// import pharmacy from "./../../assets/images/EnterpriseBody/Group 36839.png"
// import GetApp from "../../Components/GetApp"
import Helmet from "react-helmet"
import AsSeenOn from "../../Components/AsSeenOn"
import GrowthNumbers from "./../../Components/GrowthNumbers/index"
import Demo from "../../Components/Demo"
import Blogs from "../Blogs"
import EnterpriseForm from "../../Components/EnterpriseForm"
import Features from "../../Components/Features"
import { AbhaHeroSection } from "../Abdm"
import { BnplHeroSection } from "../BNPL";
import GetStarted from "../../Components/Common/GetStarted"
import { ArrowForward } from "@material-ui/icons"




function Home({ isMobile = false, isTab = false, blogList = [], isLScreen = false }) {
  const [open, setOpen] = useState(false)
  const styles = {
    ul: {
      display: "flex",
      flexDirection: "column",
      rowGap: "12px",
      paddingInlineStart: "1.5rem",
    },
    mobileHeaderImage: {
      objectFit: "contain",
      width: "95%",
    },
    headerImage: {
      objectFit: "contain",
      width: "115%",
    },
    mobileSpan: {
      background: colors.gradient,
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    span: {
      background: colors.gradient,
      color: "white",
      padding: "0.3rem",
      borderRadius: "4px",
    },
  }

  const EnterpriseBody = {
    text: "Brands",
    heading: [
      // { img: individual, text: "Small Clinic" },
      // { img: pharmacy, text: "Independent Practitioner" },
    ],
    features: [
      {
        img: eImg1,
        isDouble: true,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%", gridArea: "1 / 1", height: "auto", zIndex: 3 }
          : { objectFit: "contain", width: "100%", gridArea: "1 / 1", height: "auto", zIndex: 3 },
          // : { height: "100%", width: "100%" },
        heading: (
          <>
            Integrate with Creators who can drive customers to your brand at an insanely Low CAC!
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
             <li style={{color: "grey"}}>
            We enable <span style={{color: "white"}}>transactions between creators and their audience</span> via their <span style={{color: "white"}}>Checkout Stores</span>.
            </li>
            <li style={{color: "grey"}}>
            Choose the perfect face and voice for your brand from our <span style={{color: "white"}}>Creator-pool of 200+ creators</span> who have <span style={{color: "white"}}>50 million+ followers</span> on Instagram and socials.
            </li>
            <li style={{color: "grey"}}>
            <span style={{color: "white"}}>Feature your product</span> on creators' checkout store, <span style={{color: "white"}}>close the discovery-to-purchase loop</span> directly on Instagram, YouTube and all social media.
            </li>
            <li style={{color: "grey"}}>
            Redirect their audience as customers on your website, <span style={{color: "white"}}>trickling down CAC to a bare-minimum</span>.
            </li>
          
          
          </ul>
        ),
      },
      
      // {
      //   img: eImg3,
      //   heading: (
      //     <>
      //       Measure ROI and Optimise Sales
      //     </>
      //   ),
      //   content: (
      //     <ul style={{ ...styles.ul }}>
      //       <li style={{color: "grey"}}>
      //       Track <span style={{color: "white"}}>collaboration</span> just like any other <span style={{color: "white"}}>performance marketing campaign</span>
      //       </li>
      //       <li style={{color: "grey"}}>
      //       Measure "<span style={{color: "white"}}>Cost-per-View</span>“, “<span style={{color: "white"}}>Click Through Rate</span>” and much more.
      //       Use <span style={{color: "white"}}>website heatmap</span> to determine where the most clicks happen
      //       </li>
      //       <li style={{color: "grey"}}>
      //       Understand <span style={{color: "white"}}>drop points</span> via user journey funnels
      //       </li>
      //       <li style={{color: "grey"}}>
      //       Classify customers in different categories eg. “<span style={{color: "white"}}>High Spender</span>”, “<span style={{color: "white"}}>Buys after 3 visits on the site</span>” 
      //       </li>
      //     </ul>
      //   ),
      // },
      // {
      //   img: eImg2,
      //   heading: (
      //     <>
      //       Bring your{" "}
      //       <span style={isMobile ? styles.mobileSpan : styles.span}>
      //         {" "}
      //         staff{" "}
      //       </span>{" "}
      //       on Paperplane with you{" "}
      //     </>
      //   ),
      //   content:
      //     "Shift management chores to your receptionist and focus solely on your medical practice.Take ownership of your clinic to add or modify assistance staff, while they get a dashboard of their own.",
      // },
      {
        img: eImg3,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "130%" },
          // : { height: "100%", width: "100%" },
        heading: (
          <>
            Influencer integration with results like performance marketing
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
              Synchronise <span style={{color: "white"}}>demand from Tech</span> and <span style={{color: "white"}}>supply from your Backend Fulfilment </span> stack instantly in minutes.
            </li>
            <li style={{color: "grey"}}>
            Use <span style={{color: "white"}}>content creators</span> as <span style={{color: "white"}}>affiliates</span>, with no technical overhead and a <span style={{color: "white"}}>performance-based payment model</span>.
            </li>
            <li style={{color: "grey"}}>
            Measure <span style={{color: "white"}}>“Cost-per-View”</span>, <span style={{color: "white"}}>“Click Through Rate”</span>, <span style={{color: "white"}}>“Return-on-Ad-Spend"</span> and other insights on your <span style={{color: "white"}}>Brand Dashboard</span> and optimise growth levers.
            </li>
            <li style={{color: "grey"}}>
             <span style={{color: "white"}}>Track audience and sales</span> driven by <span style={{color: "white"}}>each creator</span> on your platform and creators' checkout stores.
            </li>
            
            
          </ul>
        ),
      },
      {
        img: eImg2,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "95%" },
          // : { height: "100%", width: "110%" },
        heading: (
          <>
            Numbers can lie, Live Recordings of user sessions don't
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            Use <span style={{color: "white"}}>Brand Dashboard</span> to Deep dive in customer behaviour via <span style={{color: "white"}}> Live Recorded video sessions</span>, understand why people are spending time but not purchasing.
            </li>
            <li style={{color: "grey"}}>
            Understand patterns and classify your website leads into <span style={{color: "white"}}>custom Customer Profiles</span>.<br />
            Eg. <span style={{color: "white"}}>"High Spender"</span>, <span style={{color: "white"}}>"Buys after 3 visits on the site"</span>.
            </li>
            <li style={{color: "grey"}}>
            <span style={{color: "white"}}>Identify the creator driving sales</span> for your products and brands among all the activated creators. For instance, out of 20 activated creators, <span style={{color: "white"}}>pinpoint the top 5</span> contributing the most to <span style={{color: "white"}}>sales</span>. 
            </li>
            <li style={{color: "grey"}}>
            Use <span style={{color: "white"}}>website heatmap</span> to determine where the most clicks happen.
            </li>
            <li style={{color: "grey"}}>
            Understand <span style={{color: "white"}}>drop points</span> via user journey funnels.
            </li>
          </ul>
        ),
      },
      {
        img: eImg4,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "140%" },
          // : { height: "100%", width: "130%" },
        heading: (
          <>
            Retarget and Convert your dropped-off customers on world’s most popular messaging app!
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            Use <span style={{color: "white"}}>Save to WhatsApp</span> and send customer favourites directly on WhatsApp along with payment link.
            </li>
            <li style={{color: "grey"}}>
            Send exclusive offers as per loyalty/behaviour (eg. <span style={{color: "white"}}>Flash sale</span>, <span style={{color: "white"}}>upto 90% OFF for next 20 mins</span>).
            </li>
            <li style={{color: "grey"}}>
            <span style={{color: "white"}}>Send updates</span> about new <span style={{color: "white"}}>stock</span> and <span style={{color: "white"}}>products</span>.
            </li>
            <li style={{color: "grey"}}>
            <span style={{color: "white"}}>Increase "Top of the mind" recall of your brand</span>, by constantly sending relevant content on the most used app. 
            </li>
          </ul>
        ),
      },
      
    ],
  }

  const Clinicbody = {
    text: "Creators",
    heading: [
      // { img: individual, text: "Small Clinic" },
      // { img: pharmacy, text: "Independent Practitioner" },
    ],
    features: [
      {
        img: img1,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "100%" }
          : { objectFit: "contain", width: "100%" },
          // : { height: "100%", width: "100%" },
        heading: (
          <>
            Up your social game and get your Dream Brand Deal!
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            No more endless nights hustling for brand deals and scrolling 'til sunrise; we've got a <span style={{color: "white"}}>curated list of brands</span> that totally get you. It's like a match made in influencer heaven!             
            </li>
          </ul>
        ),
      },
      {
        img: img2,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "130%" },
          // : { height: "100%", width: "120%" },
        heading: (
          <>
          Cash-in smoother via your Social fam!
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            Create lit content with brands that's a perfect match for <span style={{color: "white"}}>your audience and crew</span>. 
            </li>
            <li style={{color: "grey"}}>
            Awesome <span style={{color: "white"}}>Creator Automations</span> when your fam needs a link to the products you endorse.
            </li>
            <li style={{color: "grey"}}>
            Craft it with care and watch those earnings roll in while you stay true to your vibe. Let the world know you're not just posting; you're <span style={{color: "white"}}>making moves</span>!
            </li>
          </ul>
        ),
      },
      {
        img: img3,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "90%" },
          // : { height: "100%", width: "100%" },
        heading: (
          <>
          Watch your content translate into action on your Storefront
          </>
        ),
        content: (
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            Step into our Storefront where the vibes are as on point as your favorite playlist! From the freshest beats you’re bumping to the <span style={{color: "white"}}>products you’re endorsing</span>.
            </li>
            <li style={{color: "grey"}}>
            Introduce your fam to the <span style={{color: "white"}}>sickest gear, hottest tech</span>, and all the game-changers that are so your jam.  
            </li>
          </ul>
        ),
      },
      {
        img: img4,
        imgStyle: isMobile
          ? { objectFit: "contain", width: "70%" }
          : { objectFit: "contain", width: "130%" },
          // : { height: "100%", width: "120%" },
        heading: (
          <>
            Know your audience better than ever before
          </>
        ),
        content: (
          
          <ul style={{ ...styles.ul }}>
            <li style={{color: "grey"}}>
            Your Dashboard's poppin' with <span style={{color: "white"}}>graphs and analytics</span> to get the deets on every move and click that happens on your Storefront. 
            </li>
            <li style={{color: "grey"}}>
            Get an Intel on your tribe's vibe with analytics by classifying your <span style={{color: "white"}}>fam into cohorts</span>, so that you can plan your next drop in-sync.
            </li>
          </ul>
        ),
      },
    ],
  }

  const [checked, setChecked] = useState(false)
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {" "}
          Creator Checkout - Use our Creators as Resellers and convert views to purchases !
        </title>
      </Helmet>

      <Hero isMobile={isMobile} setOpen={setOpen} isLScreen={isLScreen} />
      {/* <Video /> */}
      <Grid item xs={12} style={{ margin: "1rem 0", marginTop: isMobile ? "2rem" : null, marginBottom: "-20px" }}>
        <Typography variant="h2" align="center" style={{ color: "light grey" }}>
          Trusted by 200+ Creators & Brands
        </Typography>
      </Grid>

      
      

      <Switch checked={checked} setChecked={setChecked} isMobile={isMobile} />

      <Grid item xs={12} style={{ margin: "1rem 0" }}>
        <Typography variant="h4" align="center" style={{ color: "light grey" }}>
          Our Partners
        </Typography>
      </Grid>

      <Carousel isMobile={isMobile} />

      <Switch checked={checked} setChecked={setChecked} isMobile={isMobile} numberType={false} />

      <Body
        isMobile={isMobile}
        body={checked ? Clinicbody : EnterpriseBody}
        buttonProps={{
          label: checked ? "Book a demo" : "Start Now",
          onClick: () =>
            !checked
              ? window.open("https://website.paperplane.clinic/")
              : setOpen(true),
        }}
        styles={styles}
      />
      {/* <BnplHeroSection isMobile={isMobile} /> 
      <Grid item xs={12} style={{marginTop: "4rem"}}></Grid> */}
      {/* <AbhaHeroSection isMobile={isMobile} />
      <GrowthNumbers isMobile={isMobile}></GrowthNumbers>
      <Blogs blogList={blogList} />
      <Features isMobile={isMobile}></Features> */}

      {/* {checked ? null : <GetApp isMobile={isMobile} isTab={isTab} />} */}
      {/* <Demo isMobile={isMobile} /> */}

      <Grid item xs={12} container justifyContent="center">
        <Grid item>
          <GetStarted style={{
            background: "#00D32A",
            // marginBottom: "1%"
          }}
          endIcon={<ArrowForward />}
          onClick={() => {
            if(!isMobile)
              setOpen(true)
            
            else{
              let objDiv = document.getElementById("contact-us")
              if (objDiv) {
                objDiv.scrollIntoView({ behavior: "smooth", block: 'start' })
              }
            }
          }}
          />
        </Grid>
      </Grid>
      
      {/* <Grid item xs={12} container justifyContent="center" style={{justifyContent: "center"}}>
          <Grid item style={{width: "70%"}}>
            <AboutUs isMobile={isMobile} />
          </Grid>
      </Grid> */}
      <AsSeenOn isMobile={isMobile} />
      <BackedBy isMobile={isMobile} />
      {/* <Testimonials isMobile={isMobile} /> */}
      <ContactUs isMobile={isMobile} />
      <EnterpriseForm open={open} setOpen={setOpen} isMobile={isMobile} />

      {/* <FAQ isMobile={isMobile} /> */}
    </>
  )
}

export default withWidth()(Home)
