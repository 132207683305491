import React, { useState } from "react"
import "./index.css"
import { Grid, Typography } from "@material-ui/core"
import { colors } from "../../../colors"
import CountUp from "react-countup"
import bg1 from "./../../../assets/images/Switch/bg1.png"
import bg2 from "./../../../assets/images/Switch/bg2.png"
import bg3 from "./../../../assets/images/Switch/bg3.png"
import imgLogo from "./../../../assets/images/Logos/Group 36999.png"
import AsSeenOn from "../../AsSeenOn"
import Features from "../../Features"
function Switch({ checked, setChecked, isMobile, numberType = true }) {
  const styles = {
    box: {
      minWidth: isMobile ? "200px" : "275px",
      maxWidth: isMobile ? "200px" : "275px",
      margin: "1rem",
      height: isMobile ? "170px" : "225px",
      boxShadow:
        "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
      borderRadius: "18px",

      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      justifyContent: "center",
    },
    counter: {
      fontWeight: "600",
      fontSize: isMobile ? "40px" : "55px",
    },
  }
  return (
    <div
      data-aos="fade-up"
      data-aos-delay={200}
      data-aos-once={true}
      style={{
        display: "flex",

        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        // marginTop: "1rem",
        marginBottom: "2rem"
      }}
    >
      {numberType ? <Grid
        container
        xs={12}
        spacing={4}
        alignItems="center"
        justifyContent="space-around"
        style={{ textAlign: "center", margin: "2rem 0" }}
      >
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{
            ...styles.box,
            backgroundColor: "#262338"
          }}
        >
          <Typography
            style={{
              ...styles.counter,
            }}
          >
            <CountUp
              end={300}
              suffix="+"
              separator=","
              delay={2}
              useEasing={true}
              
            ></CountUp>
          </Typography>
          <Typography variant="h3">Influencers </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          style={{
            ...styles.box,
            backgroundColor: "#262338"
          }}
        >
          <Typography
            style={{
              ...styles.counter,
            }}
          >
            <CountUp
              end={429}
              suffix="m+"
              separator=","
              delay={2}
              useEasing={true}
              
            ></CountUp>
          </Typography>

          <Typography variant="h3"> Views </Typography>
        </Grid>
        <Grid
          container
          item
          direction="column"
          style={{
            ...styles.box,
            backgroundColor: "#262338"
          }}
        >
          <Typography
            style={{
              // color: "#1ABFC7",
              ...styles.counter,
            }}
          >
            <CountUp
              end={60}
              suffix="+"
              delay={2}
              separator=","
              useEasing={true}
              
            ></CountUp>
          </Typography>
          <Typography variant="h3">Brands </Typography>
        </Grid>
      </Grid> : 
        <>
          <div
        style={{
          margin: "1.5rem 0",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          style={{
            marginLeft: "0.5rem",
            opacity: "0.6"
          }}
        >
          Run Creators as Affiliates at Scale with Zero effort
        </Typography>
      </div>
      <div className="switch-button">
        <input
          className="switch-button-checkbox"
          type="checkbox"
          value={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
          }}
        ></input>
        <label className="switch-button-label" htmlFor="switch">
          <span className="switch-button-label-span"> Brands </span>
        </label>
      </div>
        </>
      }

      
    </div>
  )
}

export default Switch
