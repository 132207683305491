import { Grid, Hidden, IconButton, Typography } from "@material-ui/core"
import React from "react"
import paperplaneLogo from "./../../assets/images/Logos/Group 36933.png"
import Insta from "./../../assets/images/Footer/Group 36587.png"
import LinkedIn from "./../../assets/images/Footer/Vector.png"
import Facebook from "./../../assets/images/Footer/bi_facebook.png"
import Twitter from "./../../assets/images/Footer/Vector (1).png"
import { useNavigate } from "react-router"
import { colors } from "../../colors"
import { Copyright } from "@material-ui/icons"
import { NavLink } from "react-router-dom"
import isoLogo from "./../../assets/images/Footer/isoLogo.png"
import hipaLogo from "./../../assets/images/Footer/hippa.png"
// import paperplaneLogo from "./../../assets/images/Logos/Group 36933.png"

function Footer({ isMobile }) {
  const whatsappLink = "a"
  const instagramLink = "b"
  const facebookLink = "c"
  const linkedInLink = "d"
  const navigate = useNavigate()
  const footerItems = [
    { to: "/vision", label: "About Us" },
    // { to: "/cancellationPolicy", label: "Refund/Cancellation Policy" },
  //  { to: "/pricing", label: "Pricing" },
    { to: "/privacyPolicy", label: "Privacy Policy" },
    { to: "/termsAndConditions", label: "Terms and Conditions" },
  ]
  const footerSocial = [
    {
      img: LinkedIn,
      to: "https://www.linkedin.com/company/paperplane-communications/",
    },
    { img: Facebook, to: "https://m.facebook.com/hq.paperplane/" },
    { img: Twitter, to: " https://twitter.com/hq_paperplane" },
    { img: Insta, to: "https://www.instagram.com/paperplanetech/" },
  ]
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: isMobile ? "8% 10%" : "3% 8%",
        bottom: 0,

        minHeight: isMobile ? "60vh" : "40vh",
        background: "rgba(38, 35, 56, 0.8)",
        borderRadius: "5rem 5rem 0 0"
      }}
    >
      <Grid
        item
        md={4}
        xs={12}
        // alignItems="center"
        container
        direction="column"
        spacing={3}
        style={{ marginTop: "1rem", position: "relative" }}
        justifyContent={"flex-start"}
      >
        <Grid item xs={12} style={{textAlign: isMobile ? "center" : null, marginLeft: isMobile ? "8%" : null}}>
          <img src={paperplaneLogo} style={{width: "160px", height: "50px"}}/>
        </Grid>
        <Grid item xs={12} style={{textAlign: isMobile ? "center" : null, position: "absolute", bottom: -25, left: isMobile ? "32%" : null}}>
          <Typography style={{marginLeft: "10px", fontSize: "16px"}}>
            by Paper<span style={{color: "#4D63DD"}}>
              plane
            </span>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        md={8}
        spacing={2}
        xs={12}
        style={{ marginTop: "1rem", marginLeft: isMobile ? "1%" : null }}
        alignItems="center"
        justifyContent={isMobile ? "center": "end"}
      >
        {footerItems.map((ele, idx) => (
          <Grid item key={idx} style={{marginLeft: isMobile ? null: "auto"}}>
            <NavLink
              to={ele.to}
              onClick={() => window?.scrollTo(0, 0)}
              style={({ isActive }) => ({
                fontFamily: "Manrope",
                textDecoration: "none",
                fontSize: "16px",
                textDecorationLine: "none",
                color: "white",
                fontWeight: "500",
              })}
            >
              {ele.label}
            </NavLink>
          </Grid>
        ))}
        {/* <Hidden smDown>
          <Grid item>
            <NavLink
              to={"/privacyPolicy"}
              onClick={() => window?.scrollTo(0, 0)}
              style={({ isActive }) => ({
                fontFamily: "Manrope",
                textDecoration: "none",
                fontSize: "16px",
                textDecorationLine: "none",
                color: "black",
                fontWeight: "500",
              })}
            >
              Privacy Policy
            </NavLink>
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item container justifyContent="flex-end">
            <NavLink
              to={"/privacyPolicy"}
              onClick={() => window?.scrollTo(0, 0)}
              style={({ isActive }) => ({
                fontFamily: "Manrope",
                textDecoration: "none",
                fontSize: "16px",
                textDecorationLine: "none",
                color: "black",
                fontWeight: "500",
              })}
            >
              About Us{" "}
            </NavLink>
          </Grid>
        </Hidden> */}
      </Grid>
      <Hidden mdUp>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent={isMobile ? "center" : "flex-end"}
          alignItems="center"
          style={{
            marginTop: "1rem",
          }}
        >
          {footerSocial.map((ele) => (
            <Grid item>
              <IconButton onClick={() => window.open(ele.to)}>
                <img
                  src={ele.img}
                  alt={ele.to}
                  style={{ color: "white", width: "32px" }}
                ></img>
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Hidden>
      <Grid item md={6} xs={12} style={{ marginTop: "1rem" }}>
        <Typography
          variant="body2"
          style={{ display: "flex", alignItems: "center", fontSize: isMobile ? "12px" : null }}
        >
          <Copyright fontSize="small" style={{color: "white", marginRight: "2px", width: isMobile ? "12px" : null}} />
          <span>
          Copyright 2023 Paperplane Communications Pvt. Ltd.
          </span>
        </Typography>
        <Typography variant="body2" style={{marginTop: "5px", fontSize: isMobile ? "12px" : null}}>
            All Rights Reserved
        </Typography>
      </Grid>
      <Hidden smDown>
        <Grid
          container
          item
          xs={12}
          md={6}
          justifyContent={isMobile ? "center" : "flex-end"}
          alignItems="center"
          style={{
            marginTop: "1rem",
          }}
        >
          {footerSocial.map((ele) => (
            <Grid item>
              <IconButton onClick={() => window.open(ele.to)}>
                <img
                  src={ele.img}
                  alt={ele.to}
                  style={{ color: "white", width: "32px" }}
                ></img>
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default Footer
